export const FloatingContactSupportButton = () => import('../../components/global/FloatingContactSupportButton.vue' /* webpackChunkName: "components/floating-contact-support-button" */).then(c => wrapFunctional(c.default || c))
export const HomeArticle = () => import('../../components/global/HomeArticle.vue' /* webpackChunkName: "components/home-article" */).then(c => wrapFunctional(c.default || c))
export const NavigationBar = () => import('../../components/global/NavigationBar.vue' /* webpackChunkName: "components/navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const JsonRenderer = () => import('../../components/json-renderer/JsonRenderer.vue' /* webpackChunkName: "components/json-renderer" */).then(c => wrapFunctional(c.default || c))
export const JsonRendererOrderedList = () => import('../../components/json-renderer/components/JsonRendererOrderedList.vue' /* webpackChunkName: "components/json-renderer-ordered-list" */).then(c => wrapFunctional(c.default || c))
export const JsonRendererOrderedListChild = () => import('../../components/json-renderer/components/JsonRendererOrderedListChild.vue' /* webpackChunkName: "components/json-renderer-ordered-list-child" */).then(c => wrapFunctional(c.default || c))
export const JsonRendererText = () => import('../../components/json-renderer/components/JsonRendererText.vue' /* webpackChunkName: "components/json-renderer-text" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeSectionAboutUs = () => import('../../components/page-features/home/SectionAboutUs.vue' /* webpackChunkName: "components/page-features-home-section-about-us" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeSectionFAQ = () => import('../../components/page-features/home/SectionFAQ.vue' /* webpackChunkName: "components/page-features-home-section-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeSectionHeader = () => import('../../components/page-features/home/SectionHeader.vue' /* webpackChunkName: "components/page-features-home-section-header" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeSectionHighlight = () => import('../../components/page-features/home/SectionHighlight.vue' /* webpackChunkName: "components/page-features-home-section-highlight" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeSectionPricing = () => import('../../components/page-features/home/SectionPricing.vue' /* webpackChunkName: "components/page-features-home-section-pricing" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeSectionTestimony = () => import('../../components/page-features/home/SectionTestimony.vue' /* webpackChunkName: "components/page-features-home-section-testimony" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesPromoBannerImage = () => import('../../components/page-features/promo/BannerImage.vue' /* webpackChunkName: "components/page-features-promo-banner-image" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesPromoCard = () => import('../../components/page-features/promo/PromoCard.vue' /* webpackChunkName: "components/page-features-promo-card" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeBestOfferCountdown = () => import('../../components/page-features/home/best-offer/BestOfferCountdown.vue' /* webpackChunkName: "components/page-features-home-best-offer-countdown" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeBestOfferSectionBestOffer = () => import('../../components/page-features/home/best-offer/SectionBestOffer.vue' /* webpackChunkName: "components/page-features-home-best-offer-section-best-offer" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeHowtoSectionHowTo = () => import('../../components/page-features/home/howto/SectionHowTo.vue' /* webpackChunkName: "components/page-features-home-howto-section-how-to" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeHowtoSlidesDekstopHowToSlides = () => import('../../components/page-features/home/howto/slides/dekstop/HowToSlides.vue' /* webpackChunkName: "components/page-features-home-howto-slides-dekstop-how-to-slides" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeHowtoSlidesDekstopHowToSlidesNavButton = () => import('../../components/page-features/home/howto/slides/dekstop/HowToSlidesNavButton.vue' /* webpackChunkName: "components/page-features-home-howto-slides-dekstop-how-to-slides-nav-button" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeHowtoSlidesDekstopHowToSlidesNavCard = () => import('../../components/page-features/home/howto/slides/dekstop/HowToSlidesNavCard.vue' /* webpackChunkName: "components/page-features-home-howto-slides-dekstop-how-to-slides-nav-card" */).then(c => wrapFunctional(c.default || c))
export const PageFeaturesHomeHowtoSlidesMobileHowToSlides = () => import('../../components/page-features/home/howto/slides/mobile/HowToSlides.vue' /* webpackChunkName: "components/page-features-home-howto-slides-mobile-how-to-slides" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
